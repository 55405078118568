import { setLoadableResponse, toggleLoadable } from "@/utils/loadable";
import Vue from "vue";

export const setInfoProceso = (state, payload) => {
    if (payload?.id_forma_contratacion === 27 && Vue.prototype.haveRole('ROLE_PROVEEDOR')) {
        payload.sacar = true
    }
    state.procesoData = payload;
};

export const setParticipando = (state, payload) => {
    state.participando = payload;
};

export const setShowModalAgregarLote = (state, payload) => {
    state.showModalAgregarLote = payload;
};

export const setShowModalAddEspecificacion = (state, payload) => {
    if (typeof payload === "boolean") {
        // Si es un booleano, mantenemos la compatibilidad con el uso antiguo
        state.showModalAddEspecificacion = payload;
    } else if (typeof payload === "object") {
        // Si es un objeto, usamos sus propiedades
        state.showModalAddEspecificacion = payload.show;
        state.showAddEspecificacionHerramienta = payload.herramienta ?? false;
    }
};

export const setDatosLoteSeleccionado = (state, payload) => {
    state.datosLoteSeleccionado = payload;
};

export const setLotes = (state, payload) => {
    state.lotes = payload;
};

export const setShowModalDeleteLote = (state, payload) => {
    state.showModalDeleteLote = payload;
};

export const setIdLoteAccion = (state, payload) => {
    state.idLoteAccion = payload;
};

export const setSelectedLot = (state, payload) => {
    state.selectedLot = payload;
};

export const setOffers = (state, payload) => {
    state.offers = payload;
}

export const setOffer = (state, payload) => {
    state.offer = payload;
}

export const setFechaResolucion = (state, payload) => {
    state.fecha_resolucion = payload;
}

export const setExistSolicitud = (state, payload) => {
    state.exist_solicitud = payload;
}

export const setListContrato = (state, payload) => {
    state.list_contrato_st = payload
}

export const empezarPeticionSiguientesEtapasImpugnacion = (state) => {
    toggleLoadable(state.siguientesEtapasImpugnacion);
}

export const manejarPeticionSiguientesEtapasImpugnacion = (state, payload) => {
    setLoadableResponse(state.siguientesEtapasImpugnacion, payload);
}

export const setGanadores = (state, ganadores) => {
    state.ganadores = ganadores;
};

// Comision de alto nivel
export const empezarPeticionVerificarParticipacionEnComision = (state) => {
    toggleLoadable(state.verificacionParticipacionComision);
}

export const manejarPeticionVerificarParticipacionEnComision = (state, payload) => {
    setLoadableResponse(state.verificacionParticipacionComision, payload);
}

