const routerPacSubasta = [
  {
    path: "/procesos-compra-subasta",
    name: "procesos-compra-subasta",
    component: () =>
      import(
        /* webpackChunkName: "procesos-compra-subasta" */ "./PACSubastaListView.vue"
      ),
  },
  {
    path: "/procesos-compra-subasta/:id_proceso",
    name: "procesos-compra-subasta-detalle",
    component: () =>
      import(/* webpackChunkName: "procesos-compra-subasta-detalle" */ "./PACSubastaDetalleView.vue"),
  },
];

export default routerPacSubasta;
