import { http_client_slim } from "@/plugins/http_client";

const client = http_client_slim("/api/v1/perfiles");

const obtenerPerfiles = (id_usaurio = null) =>
  client(`/perfiles/${id_usaurio ?? ""}`);

const obtenerRoles = (id_usaurio = null) =>
  client(`/roles/${id_usaurio ?? ""}`);

const agregarRol = (id_usaurio, id_rol) =>
  client(`/roles/agregar`, { method: "post", data: { id_usaurio, id_rol } });

const agregarPerfil = (id_usaurio, id_perfil) =>
  client(`/perfiles/agregar`, {
    method: "post",
    data: { id_usaurio, id_perfil },
  });

const eliminarPerfilUsuario = (id_usaurio, id_perfil) =>
  client(`/perfiles/eliminar`, {
    method: "delete",
    data: { id_usaurio, id_perfil },
  });

const eliminarRolUsuario = (id_usaurio, id_rol) =>
  client(`/roles/eliminar`, {
    method: "delete",
    data: { id_usaurio, id_rol },
  });

export default {
  obtenerPerfiles,
  obtenerRoles,
  agregarRol,
  agregarPerfil,
  eliminarPerfilUsuario,
  eliminarRolUsuario,
};
