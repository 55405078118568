import compraEnExterior from "@/services/CompraExterior.services";
import paisesServices from "@/services/Paises.services";
import Vue from "vue";

export default {
  namespaced: true,
  state: {
    cargando: false,
    listadoRegistros: [],
    paginacion: {
      page: 1,
      per_page: 10,
      total_elements: 0,
    },
    filters: {
      id_institucion: null,
      id_unidad: null,
      id_region: null,
      id_pais: null,
      denominacion: null,
      fecha_inicio: null,
      fecha_fin: null,
      id_fuente_financiamiento: null,
    },
    formulario: {
      id_pais: null,
      nombre_compra: null,
      insumos_adquiridos: null,
      monto_contratado: null,
      proveedor: null,
      fecha_contratacion: null,
      contratos: null,
      id_divisa: null,
      monto_divisa: null,
      estado: null,
      id_fuente_financiamiento: null,
    },
    default_formulario: {
      id_pais: null,
      nombre_compra: null,
      insumos_adquiridos: null,
      monto_contratado: null,
      proveedor: null,
      fecha_contratacion: null,
      contratos: null,
      id_divisa: null,
      monto_divisa: null,
      estado: null,
      id_fuente_financiamiento: null,
    },
    paises: [],
    instituciones: [],
    divisas: [],
    fuentesFinanciamiento: [],
  },
  mutations: {
    setListadoRegistros(state, payload) {
      state.listadoRegistros = payload;
    },
    setPaginacion(state, payload) {
      state.paginacion = payload;
    },
    setIdInstitucion(state, payload) {
      state.filters.id_institucion = payload;
    },
    setIdUnidad(state, payload) {
      state.filters.id_unidad = payload;
    },
    setIdRegion(state, payload) {
      state.filters.id_region = payload;
    },
    setIdPais(state, payload) {
      state.filters.id_pais = payload;
    },
    setFechaInicio(state, payload) {
      state.filters.fecha_inicio = payload;
    },
    setFechaFin(state, payload) {
      state.filters.fecha_fin = payload;
    },
    setFuenteFinanciamiento(state, payload) {
      state.filters.id_fuente_financiamiento = payload;
    },
    setDenominacion(state, payload) {
      state.filters.denominacion = payload;
    },
    setFormulario(state, payload) {
      state.formulario = payload;
    },
    resetForm(state) {
      state.formulario = { ...state?.default_formulario };
    },
    setCargando(state, payload) {
      state.cargando = !state.cargando;
    },
    setCargandoTrue(state, payload) {
      state.cargando = false;
    },
    setPaises(state, payload) {
      state.paises = payload;
    },
    setInstituciones(state, payload) {
      state.instituciones = payload;
    },
    setDivisas(state, payload) {
      state.divisas = payload;
    },
    setFuentesFinanciamiento(state, payload) {
      state.fuentesFinanciamiento = payload;
    },
  },
  actions: {
    async getListadoRegistros({ commit, state }) {
      commit("setCargando");
      commit("setListadoRegistros", []);
      const { data, headers } = await compraEnExterior.listarRegistro({
        ...state?.paginacion,
        id_institucion: state?.filters?.id_institucion,
        id_pais: state?.filters?.id_pais,
        id_region: state?.filters?.id_region,
        id_unidad: state?.filters?.id_unidad,
        denominacion: state?.filters?.denominacion,
        fecha_inicio: state?.filters?.fecha_inicio,
        fecha_fin: state?.filters?.fecha_fin,
        id_fuente_financiamiento: state?.filters?.id_fuente_financiamiento,
      });
      commit("setListadoRegistros", data);
      commit("setPaginacion", {
        page: parseInt(headers?.page),
        per_page: parseInt(headers?.per_page),
        total_elements: parseInt(headers?.total_rows),
      });
      commit("setCargando");
    },
    async getPaises({ commit }) {
      const { data } = await paisesServices.getListaPaises();
      commit("setPaises", data);
    },
    async getFuentesFinanciamiento({ commit }) {
      const { data } = await compraEnExterior.getFuentesFinanciamiento({
        pagination: false,
        catalogo: true,
      });
      commit("setFuentesFinanciamiento", data);
    },
    async guardarRegistro({ commit, state }, callback) {
      commit("setCargando");
      const formData = new FormData();
      formData.append("id_fuente_financiamiento", state?.formulario?.id_fuente_financiamiento);
      formData.append("nombre_compra", state?.formulario?.nombre_compra);
      formData.append(
        "insumos_adquiridos",
        state?.formulario?.insumos_adquiridos
      );
      formData.append("monto_contratado", state?.formulario?.monto_contratado);
      formData.append("proveedor", state?.formulario?.proveedor);
      formData.append(
        "fecha_contratacion",
        state?.formulario?.fecha_contratacion
      );
      formData.append("contratos", state?.formulario?.contratos);
      formData.append("id_divisa", state?.formulario?.id_divisa);
      formData.append("monto_divisa", state?.formulario?.monto_divisa);
      formData.append("estado", state?.formulario?.estado);
      compraEnExterior
        .guardarRegistro(formData)
        .catch((error) => {
          /* Vue?.prototype.pushAppMessage({
            message: "Error al guardar, intenté más tarde",
            type: "warning",
          }); */
          commit("setCargando");
        })
        .then(() => {
          commit("setCargando");
          commit("resetForm");
          callback();
        });
    },
    async resetForm({ commit }) {
      commit("resetForm");
    },
    setCargandoTrue({ commit }) {
      commit("setCargandoTrue");
    },
    async getInstituciones({ commit }) {
      const { data } = await compraEnExterior.listarInstituciones();
      commit("setInstituciones", data);
    },
    setFiltroInstitucion({ commit }, payload) {
      commit("setIdInstitucion", payload);
    },
    setFiltroRegion({ commit }, payload) {
      commit("setIdRegion", payload);
    },
    setFiltroPais({ commit }, payload) {
      commit("setIdPais", payload);
    },
    setFiltroUnidad({ commit }, payload) {
      commit("setIdUnidad", payload);
    },
    setFiltroDenominacion({ commit }, payload) {
      commit("setDenominacion", payload);
    },
    setFiltroFechaInicio({ commit }, payload) {
      commit("setFechaInicio", payload);
    },
    setFiltroFechaFin({ commit }, payload) {
      commit("setFechaFin", payload);
    },
    setFiltroFuenteFinanciamiento({ commit }, payload) {
      commit("setFuenteFinanciamiento", payload);
    },
    paginar({ commit, dispatch, state }, payload) {
      const { cantidad_por_pagina, pagina } = payload;
      commit("setPaginacion", {
        page: parseInt(pagina),
        per_page: parseInt(cantidad_por_pagina),
        total_elements: parseInt(state?.paginacion?.total_rows),
      });
    },
    async getDivisas({ commit }) {
      const { data } = await compraEnExterior.listDivisas();
      commit("setDivisas", data);
    },
    async descargarInformeComprasExterior({ commit, state }) {
      Vue.prototype.showLoader();
      const { data, headers } =
        await compraEnExterior.descargarInformeComprasExterior({
          id_institucion: state?.filters?.id_institucion,
          id_pais: state?.filters?.id_pais,
          id_region: state?.filters?.id_region,
          id_unidad: state?.filters?.id_unidad,
          denominacion: state?.filters?.denominacion,
          fecha_inicio: state?.filters?.fecha_inicio,
          fecha_fin: state?.filters?.fecha_fin,
          pagination: false,
        });
      Vue.prototype.hideLoader();

      const blob = new Blob([data], {
        type: headers["content-type"],
      });

      const file = new File([blob], "InformeComprasExterior.pdf", {
        type: headers["content-type"],
      });

      const link = document.createElement("a");
      link.href = window.URL.createObjectURL(file);
      link.download = "InformeComprasExterior.pdf";
      link.click();
    },
  },
};
