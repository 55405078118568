import {http_client} from "@/plugins/http_client";

const getListaPaises = async(params ={})=>{
    return await http_client("/api/v1/paises", params, "get");
}

const getListaRegiones = async(params ={})=>{
    return await http_client("/api/v1/paises/regiones", params, "get");
}

export default{
    getListaPaises,
    getListaRegiones
}
