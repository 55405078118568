import PaacServices from "../../../../services/Paac.services";
import ContratoServices from "../../../../services/Contrato.services";
import modificacionLotes from "@/services/ModificacionLotes.services";

import Vue from "vue";

export const getInfoGeneralProcesoCompra = async (
  { commit },
  id_proceso_compra
) => {
  const response = await PaacServices.getInfoGeneralProcesoCompra(
    id_proceso_compra
  );
  if (response.status === 200) {
    commit("setInfoGeneral", response.data);
  }
  return response;
};

export const putInfoGeneralProcesoCompra = async ({ commit }, data) => {
  const response = await PaacServices.putInforGeneralProcesoCompra(
    data.id,
    data.params
  );
  return response;
};

export const getListaTecnicosProcesoCompra = async (
  { commit },
  id_proceso_compra
) => {
  const response = await PaacServices.getTecnicosProcesoCompra(
    id_proceso_compra
  );
  if (response.status === 200) {
    commit("setListaTecnico", response.data);
  }
};

export const getListaDocumentosProcesoCompra = async ({ commit }) => {
  const response = await PaacServices.getListaDocumentosProcesoCompra();
  if (response.status === 200) {
    commit("setListaTipoDocumentos", response.data);
  }
};

export const getDocumentosProcesoCompra = async (
  { commit },
  {id_proceso_compra, filtros}
) => {
  const response = await PaacServices.getDocumentosProcesoCompra(
    id_proceso_compra,
    filtros
  );
  if (response.status === 200) {
    commit("setListaDocumentos", response.data);
    commit("setPaginationDocumentos", response.headers);
  }
};

export const getOfertasProcesoCompra = async (
  { commit },
  {id_proceso_compra, filtros}
) => {
  const response = await PaacServices.getEvaluacionUaciOferta(
    id_proceso_compra,
    filtros
  );
  if (response.status === 200) {
    commit("setListaOfertas", response.data);
    commit("setPaginationOfertas", response.headers);
  }
};

export const postDocumentoProcesoCompra = async ({ commit }, data) => {
  const params = new FormData();
  params.append("id_tipo_documento", data.params.id_tipo_documento);
  params.append("documento", data.params.documento);
  const response = await PaacServices.postDocumentosProcesoCompra(
    data.id,
    params
  );
  return response;
};

export const deleteDocumentoProcesoCompra = async (
  { commit },
  id_documento_proceso
) => {
  const response = await PaacServices.deleteDocumentoProcesoCompra(
    id_documento_proceso
  );
  return response;
};

export const getFasesProcesoCompra = async ({ commit }, id_proceso_compra) => {
  const response = await PaacServices.getEtapasProcesoCompra(id_proceso_compra);
  if (response.status === 200) {
    commit("setListaFases", response.data);
  }
};

export const updateFasesProcesoCompra = async ({ commit }, data) => {
  const response = await PaacServices.putEtapasProcesoCompra(
    data.id,
    data.params
  );
  return response;
};

export const postDocumentoFasesProcesoCompra = async ({ commit }, data) => {
  const params = new FormData();
  params.append("documento", data.documento);
  const response = PaacServices.postDocumentoEtapasProcesoCompra(
    data.id,
    params
  );
  return response;
};

export const getEtapasContrato = async ({ commit, state }) => {
  Vue.prototype.showLoader()
  const response = await ContratoServices.getContratoEtapasList(
    state.idOrdenCompra,
    state.filters
  );
  if (response.status === 200) {
    commit("obtenerEtapas", response.data);
    commit("setPaginationData", response.headers);
  }
  Vue.prototype.hideLoader()
};

export const getEtapasProgreso = async ({ commit, state }) => {
  const response = await ContratoServices.getEtapasProgreso(
    state.idOrdenCompra
  );
  if (response.status === 200) {
    commit("getEtapaProgreso", response.data);
  }
};

export const getListadoProveedores = async ({ commit, state }, payload) => {
  const params = {
    busqueda: state.nombre_comercial,
  };

  const response = await PaacServices.getListarProveedores(params);
  if (response.status === 200) {
    commit("setListaProveedores", response.data);
  }
};

export const setNombreComercial = async ({ commit }, payload) => {
  commit("setNombreComercial", payload);
};

export const setProveedor = async ({ commit }, payload) => {
  commit("setProveedor", payload);
};

export const invitarProveedor = async ({ commit, state, dispatch }, payload) => {
  const params = {
    correo: state.proveedor_invitar.email,
  };
  const response = await PaacServices.postInvitarProveedor(
    state.info_general.id,
    params
  );
  return response;
};

// Publicar proceso de compra
export const publicarProcesoCompra = async ({ commit, state, dispatch }) => {
  const params = {
    id_seguimiento_proceso: 2,
  };

  commit("setPublicarLoading", true);
  const response = await PaacServices.putEstadoProceso(
    state.info_general.id,
    params
  );

  if (response.status === 200) {
    commit("setPublicarLoading", false);
    dispatch("getInfoGeneralProcesoCompra", state.info_general.id);
  }
  commit("setPublicarLoading", false);
  return response;
};


export const getRecepcionOfertas = async ({ commit }, procesoId) => {
  const response = await PaacServices.getRecepcionOfertas(procesoId);
  return response;
}

export const putFechasRecepcionOfertas = async ({ commit }, payload) => {
  const { procesoCompraId, body } = payload
  const response = await PaacServices.putFechasRecepcionOfertas(procesoCompraId, body);
  return response;
}

export const getObsRecepcionOfertas = async ({ commit }, procesoObsId) => {
  const response = await PaacServices.getObsRecepcionOfertas(procesoObsId);
  return response;
}

export const getObsDetalleRecepcionOfertas = async ({ commit }, payload) => {
  const { id_proceso_compra, body } = payload
  const response = await PaacServices.getObsDetalleRecepcionOfertas( id_proceso_compra, body );
  return response;
}

export const getSubprocesoObsRecepcionOfertas = async ({ commit }, payload ) => {
  const { idProcesoCompra, filtros } = payload
  const response = await PaacServices.getSubprocesoObsRecepcionOfertas( idProcesoCompra, filtros);
  return response;
}

export const postSubprocesoObsRecepcionOfertas = async ({ commit }, payload) => {
  const { id_proceso_compra, body } = payload
  const response = await PaacServices.postSubprocesoObsRecepcionOfertas(id_proceso_compra, body);
  return response;
}

export const postSubprocesoObsRecepcionOfertasHerramienta = async ({ commit }, payload) => {
  const { id_proceso_compra, body } = payload
  const response = await modificacionLotes.postSubprocesoObsRecepcionOfertasHerramienta(id_proceso_compra, body);
  return response;
}

export const getInfoSubprocesoObs = async ({ commit }, payload ) => {
  const response = await PaacServices.getInfoSubprocesoObs( payload );
  return response;
}

export const putSubprocesoRecepcionOferta = async ({ commit }, payload) => {
  const { id_sub_proceso_obs, body } = payload
  const response = await PaacServices.putSubprocesoRecepcionOferta(id_sub_proceso_obs, body);
  return response;
}

export const deleteSubprocesoRecepcionOferta = async ({ commit }, subprocesoId) => {
  const response = await PaacServices.deleteSubprocesoRecepcionOferta(subprocesoId);
  return response;
}

export const publicarProcesoPaac = async ({ commit }, data) => {
  const response = await PaacServices.publicarProcesoPaac(
    data.id_proceso_compra,
    data.params
  );
  return response;
};