import { http_client, http_client_with_status } from "../plugins/http_client";

const http_client_slim = async (url, parametros = {}, metodo = "get") =>
  http_client(`/api/v1/eliminar/etapas/contratos/${url}`, parametros, metodo);

const obtenerAdjuntoModificativa = async (idContrato) =>
  http_client_slim(`adjunto-modificativa/${idContrato}`);

const obtenerContratoByCorrelativo = async (id_proceso_compra, params) =>
  http_client_slim(`${id_proceso_compra}/listar`, params);

const obtenerEtapasByContratoid = async (id_contrato, params) =>
  http_client_slim(`${id_contrato}/etapas`, params);

const eliminarContratoById = async (id_contrato, id_proceso, params) =>
  http_client_slim(`eliminar-contrato-selected/${id_proceso}/${id_contrato}`, params, 'delete');

const eliminarEtapaContratoById = async (id_proceso_compra, id_etapa, id_contrato, params) =>
  http_client_slim(`eliminar-etapa/${id_proceso_compra}/${id_etapa}/${id_contrato}`, params, 'delete');

const subirModificativaEtapa = async (idProceso, idModificativa, body) =>
  http_client_slim(`${idProceso}/${idModificativa}`, body, "post");

const obtenerProcesoCompraByContrato = async (params) =>
  http_client_slim(`proceso-compra/contrato`, params);

const obtenerDocumentosContrato = async (id_contrato, params) =>
  http_client_slim(`${id_contrato}/proceso-compra/documentos-contrato`, params);

const eliminarDocumentoContratoById = async (id_proceso_compra, id_documento, id_contrato, params) =>
  http_client_slim(`eliminar-documento-contrato/${id_proceso_compra}/${id_documento}/${id_contrato}`, params, 'delete');

const modificarMontoContractual = async (id_proceso_compra, id_contrato, body = {}) =>
  http_client_slim(`modificar-monto-contractual/${id_proceso_compra}/${id_contrato}`, body, 'put');

const guardarDocumento = (id_contrato, params) => http_client_with_status(`api/v1/eliminar/etapas/contratos/guardar-documento/${id_contrato}`, params, 'post', { 'Content-Type': 'multipart/form-data' });

const obtenerEstadosContrato = async () =>
  http_client_slim(`estados-contratos`);

const guardarEstado = (id_contrato, params) => http_client_with_status(`api/v1/eliminar/etapas/contratos/guardar-estado/${id_contrato}`, params, 'put', {'Content-Type': 'multipart/form-data'});

const obtenerPagosPorEtapa = (id_etapa_contrato, params) => http_client_with_status(`api/v1/eliminar/etapas/contratos/obtener-pagos/${id_etapa_contrato}`, params, 'get');

const eliminarPago = (id_movimiento, params) => http_client_with_status(`api/v1/eliminar/etapas/contratos/eliminar-pago/${id_movimiento}`, params, 'delete');

const actualizarVersion = async (id_proceso_compra,id_contrato) => http_client_slim(`actualizar-version/${id_proceso_compra}/${id_contrato}`, {}, 'patch');

export default {
  obtenerAdjuntoModificativa,
  obtenerContratoByCorrelativo,
  eliminarContratoById,
  obtenerEtapasByContratoid,
  eliminarEtapaContratoById,
  subirModificativaEtapa,
  obtenerProcesoCompraByContrato,
  obtenerDocumentosContrato,
  eliminarDocumentoContratoById,
  modificarMontoContractual,
  guardarDocumento,
  obtenerEstadosContrato,
  guardarEstado,
  obtenerPagosPorEtapa,
  eliminarPago,
  actualizarVersion
};
