import { http_client } from "../plugins/http_client";

const http_client_slim = async (url, parametros = {}, metodo = "get") =>
  http_client(`/api/v1/herramienta-lotes/${url}`, parametros, metodo);

const lotesParticipacionesProveedoresHerramienta = (id_proceso_compra, params) =>
  http_client_slim(`revision/participaciones-proveedor/${id_proceso_compra}`, params, "get");

const lotesParticipacionProveedorHerramienta = (id_participacion, id_proceso_compra, params) =>
  http_client_slim(`participacion-proveedor/${id_proceso_compra}/${id_participacion}`, params, "get");

const getOfertasHerramienta = (id_participacion, params) =>
  http_client_slim(`ofertas/${id_participacion}`, params, "get");

const getProveedoresHerramienta = (params = {}) =>
  http_client_slim(`listar/proveedor`, params, "get");

const storeLoteProcesoHerramienta = async (id_proceso_compra, parametros) => 
  http_client_slim(`${id_proceso_compra}/lotes`, parametros, "post");

const separarAgrupadorHerramienta = async (id_lote, parametros) =>
  http_client_slim(`separar/agrupador/${id_lote}`, parametros, "put");

const agregarParticipacionProveedorLoteHerramienta = async (body = {}) =>
  http_client_slim(`agregar/participacion-proveedor`, body, "put");

const editEspecificacionLoteHerramienta = async (body = {}) =>
  http_client_slim(`editar/especificacion`, body, "put");

const agregarParticipacionHerramienta = async (id_proceso_compra, id_proveedor, params) =>
  http_client_slim(`agregar/participacion/${id_proceso_compra}/${id_proveedor}`, params, "post");

const agregarCalificacionOfertaHerramienta = async (id_oferta, params = {}) =>
  http_client_slim(`agregar-calificacion-oferta/${id_oferta}`, params, "put");

const actualizarDatosLoteHerramienta = async (id_lote, params = {}) => 
  http_client_slim(`update/${id_lote}`, params, "put");

const postSubprocesoObsRecepcionOfertasHerramienta = async (idProcesoCompra, params = {}) =>
  http_client_slim(`proceso-obs/${idProcesoCompra}/sub-proceso`, params, "post");

const agregarOfertaProveedorHerramienta = async (id_participacion_proveedor, body) =>
  http_client_slim(`agregar/oferta-proveedor-participacion/${id_participacion_proveedor}`,
    body, 
    "post", 
    {"Content-Type": "multipart/form-data" }
  );

const deleteLoteHerramienta = async (id_lote, parametros) =>
  http_client_slim(`${id_lote}`, parametros, "delete");

const eliminarOfertaHerramienta = async (id_oferta, params) => 
  http_client_slim(
    `/oferta-proveedor/${id_oferta}`,
    params,
    "delete"
  );

const eliminarAgrupadorHerramienta = async (id_sub_proceso, params) =>
  http_client_slim(`/agrupador/${id_sub_proceso}`, params, "delete");

export default {
  lotesParticipacionesProveedoresHerramienta,
  lotesParticipacionProveedorHerramienta,
  getOfertasHerramienta,
  getProveedoresHerramienta,
  storeLoteProcesoHerramienta,
  separarAgrupadorHerramienta,
  agregarCalificacionOfertaHerramienta,
  agregarParticipacionHerramienta,
  agregarParticipacionProveedorLoteHerramienta,
  editEspecificacionLoteHerramienta,
  actualizarDatosLoteHerramienta,
  postSubprocesoObsRecepcionOfertasHerramienta,
  agregarOfertaProveedorHerramienta,
  deleteLoteHerramienta,
  eliminarOfertaHerramienta,
  eliminarAgrupadorHerramienta,
};
