<template>
    <v-dialog
      max-width="1200px"
      v-model="internalOpen"
      persistent
      v-if="offerMeta"
    >
        <div style="background-color: #FFFFFF; position: relative;">
            <div style="width: 100%; background-color: inherit; position: sticky; top: 0; z-index: 10;">
                <div class="px-2 py-2">
                    <v-btn 
                        style="min-width: unset !important; min-height: unset !important; width: 32px !important; height: 32px !important;"  
                        @click.stop="internalOpen = false" 
                        text
                        rounded
                    >
                        <v-icon>mdi-window-close</v-icon>
                    </v-btn>
                </div>
            </div>
            <template v-if="!justForm && offer">
                <h3 class="text-uppercase pl-4">Detalle de oferta</h3>
                <v-divider color="gray" class="mt-2" /> 
                <div class="px-4 py-4">
                    <v-row>
                        <v-col :cols="isProductOffer ? 6 : 12">
                            <p class="my-0"><b>Proveedor: </b> {{ offer.Proveedor.nombre  }}</p>
                        </v-col>
                        <v-col :cols="isProductOffer ? 6 : 12">
                            <p class="my-0"><b>Precio: </b> ${{ offer.precio  }}</p>
                        </v-col>
                        <v-col cols="12" v-if="offer.ofeProCon_atrOfe?.length > 0">
                            <b>Atributos: </b> 
                                    <v-chip v-for="({ CMAtributoSubgrupo, valor }, index) in offer.ofeProCon_atrOfe" class="mx-1 my-1" :key="index">
                                {{ `${CMAtributoSubgrupo.CMAtributo.nombre}: ${valor} ${CMAtributoSubgrupo.CMAtributo.unidad??''}` }}
                            </v-chip>
                        </v-col>
                        <v-col cols="12" v-if="isProductOffer">
                            <b>Regiones: </b>
                            <v-data-table
                                :headers="regionsHeaders"
                                :items="offer.conMar_regCon"
                                class="mt-3"
                                hide-default-footer
                            >
                                <template v-slot:item.tiempo_respuesta="{ item }"> 
                                    {{ formatHour(item.tiempo_respuesta) }}                                   
                                </template>
                                <template v-slot:item.tiempo_respuesta_eme="{ item }"> 
                                    {{ formatHour(item.tiempo_respuesta_eme) }}                                   
                                </template>
                            </v-data-table>
                        </v-col>

                        <v-col cols="12">
                            <b>Asignaciones presupuestarias: </b>
                            <v-data-table
                                :headers="asignacionHeaders"
                                :items="asignaciones.data"
                                class="mt-3"
                                :no-data-text="'No hay certificaciones realizadas'"
                                disable-pagination
                                hide-default-footer
                            >
                                <template v-slot:item.asignacion_seleccionada="{ item }">
                                    <div style="display: flex; justify-content: center; align-items: center; height: 100%;">
                                        <v-checkbox
                                        v-model="item.seleccionado"
                                        :value="item.id"
                                        :readonly="!mostrarAsignacionesObs(item)"
                                        @change="onCheckboxChange(item)"
                                        />
                                    </div>
                                </template>
                                <template v-slot:item.nombre_obs="{ item }"> 
                                    {{   `${item.Obs.codigo}- ${item.Obs.nombre}` }}
                                </template>
                                <template v-slot:item.monto_disponible="{ item }"> 
                                    {{  `$ ${item.monto}` }}
                                </template>
                                <template v-slot:item.cifrado_presupuestario="{ item }"> 
                                    {{ item.cifrado_presupuestario }}
                                    <br>
                                    <span class="font-weight-bold"> {{ item.solicitud_necesidad }}</span>
                                </template>
                            </v-data-table>
                        </v-col>
                    </v-row>
                </div>
            </template>
            <h3 class="pb-0 pl-4 text-uppercase" :class="!justForm && 'pt-4'">{{ modalTitle }}</h3>
            
            <v-divider color="gray" class="mt-2" /> 
            <div class="px-4 py-4">
                <v-form ref="form" @submit.prevent="submit()">
                    <v-text-field
                        v-if= manejoCifra 
                        v-model="form.cifra"
                        :label="ValorCifra"
                        type="text"
                        required
                        min="0"
                        outlined
                        :error-messages="cifraErrors"
                        @change="$v.form.cifra.$touch()"
                        @blur="$v.form.cifra.$touch()"
                        style="width: 100%"
                    />
                    <v-text-field
                        v-if= manejoCantidad 
                        v-model="form.cantidad"
                        :label="ValorCantidad"
                        type="text"
                        required
                        :step="offerMeta?.cantidad_decimal ? 0.5 : 1"
                        min="0"
                        outlined
                        :error-messages="cantidadErrors"
                        @change="$v.form.cantidad.$touch()"
                        @blur="$v.form.cantidad.$touch()"
                        style="width: 100%"
                    />
                    <v-text-field
                        v-if= manejoVigencia 
                        v-model="form.vigencia"
                        label="Vigencia"
                        type="number"
                        required
                        min="0"
                        outlined
                        :error-messages="vigenciaErrors"
                        @change="$v.form.vigencia.$touch()"
                        @blur="$v.form.vigencia.$touch()"
                        style="width: 100%"
                    />
                    <v-text-field
                        v-if="manejoAltura"
                        v-model="form.altura"
                        label="Altura"
                        required
                        type="text"
                        outlined
                        :error-messages="alturaErrors"
                        @change="$v.form.altura.$touch()"
                        @blur="$v.form.altura.$touch()"
                        style="width: 100%"
                    />
                    <v-text-field
                        v-if=manejoBase
                        v-model="form.base"
                        label="Base"
                        required 
                        type="text"
                        min="0"
                        outlined
                        :error-messages="anchuraErrors"
                        @change="$v.form.base.$touch()"
                        @blur="$v.form.base.$touch()"
                        style="width: 100%"
                    />
                    <v-text-field
                        v-if=manejoMonto
                        v-model="form.monto"
                        label="Monto"
                        required 
                        type="number"
                        min="0"
                        outlined
                        :error-messages="montoErrors"
                        @change="$v.form.monto.$touch()"
                        @blur="$v.form.monto.$touch()"
                        style="width: 100%"
                    />
                    <v-text-field
                        v-if=manejoLineas
                        v-model="form.lineas"
                        label="Líneas"
                        required 
                        type="number"
                        min="0"
                        outlined
                        :error-messages="lineasErrors"
                        @change="$v.form.lineas.$touch()"
                        @blur="$v.form.lineas.$touch()"
                        style="width: 100%"
                    />
                    <v-select 
                        v-if="!isProductOffer && manejoDireccion"
                        :items="direcciones"
                        item-text="direccion"
                        item-value="id"
                        label="Dirección"
                        v-model="form.direccion"
                        outlined
                        :error-messages="direccionErrors"
                        @change="$v.form.direccion.$touch()"
                        @blur="$v.form.direccion.$touch()"
                        light
                        class="mb-4"
                    />
                    <v-row>
                        <v-col :cols="manejoFecha && manejoHora ? 6 : 12" v-if="manejoFecha">
                            <v-menu transition="scale-transition" min-width="auto">
                                <template v-slot:activator="{ on, attrs }">
                                    <div v-bind="attrs" v-on="on">
                                        <v-text-field 
                                            label="Fecha" 
                                            placeholder="Fecha"
                                            prepend-inner-icon="mdi mdi-calendar-range"
                                            light 
                                            outlined 
                                            readonly
                                            :error-messages="fecha_agendamientoErrors"
                                            :value="fecha_agendamientoValue"
                                            @change="$v.form.fecha_agendamiento.$touch()"
                                            @blur="$v.form.fecha_agendamiento.$touch()"
                                        />
                                    </div>
                                </template>
                                <div @click.stop>
                                    <vc-date-picker 
                                        v-model="form.fecha_agendamiento"
                                        mode="date"
                                        locale="es"    
                                        :min-date="minDate"                    
                                    />
                                </div>
                            </v-menu>
                        </v-col>
                        <v-col :cols="manejoFecha && manejoHora ? 6 : 12" v-if="manejoHora">
                            <v-menu transition="scale-transition" min-width="auto">
                                <template v-slot:activator="{ on, attrs }">
                                    <div v-bind="attrs" v-on="on">
                                        <v-text-field 
                                            label="Hora" 
                                            placeholder="Hora"
                                            light 
                                            prepend-inner-icon="mdi mdi-clock-outline"
                                            outlined 
                                            readonly
                                            :error-messages="hora_agendamientoErrors"
                                            :value="hora_agendamientoValue"
                                            @change="$v.form.hora_agendamiento.$touch()"
                                            @blur="$v.form.hora_agendamiento.$touch()"
                                        />
                                    </div>
                                </template>
                                <div @click.stop>
                                    <vc-date-picker 
                                        hide-time-header
                                        v-model="form.hora_agendamiento"
                                        mode="time"
                                        locale="es"    
                                        class="fecha-form-time-picker"
                                    />
                                </div>
                            </v-menu>
                        </v-col>
                    </v-row>

                    <v-file-input
                        v-if="manejoPdf"
                        v-model="form.adjunto"
                        label="PDF"
                        placeholder="Seleccione un PDF"
                        outlined
                        light
                        accept="application/pdf"
                    />
                    <!-- Fondo si tiene una oferta -->
                    <RetryDataLoading :loadable="listaOfertasGastos" :retryHandler="retryFundsLoading" v-if="mostrarFondoGastoAdministrativo">
                        <v-select
                            :items="ofertasGastos"
                            :loading="listaOfertasGastos.isLoading || fundAmounts.isLoading"
                            item-value="id"
                            label="Fondo gastos"
                            item-disabled="disabled"
                            v-model="form.id_asignacion_gasto"
                            outlined
                            :error-messages="idOfertaGastoErrors"
                            @change="$v.form.id_asignacion_gasto.$touch()"
                            @blur="$v.form.id_asignacion_gasto.$touch()"
                            light
                            class="mt-4"
                        >
                        <template v-slot:selection="{ item }">
                            <v-list-item class="px-0" :disabled="!(item.monto > 0)" style="width: 100%;">
                                <v-list-item-content class="py-0">
                                    <v-list-item-title class="text-truncate" :title="item.cifrado_presupuestario">
                                    {{ item.cifrado_presupuestario }}
                                    </v-list-item-title>
                                    <v-list-item-subtitle class="text-truncate" :title="item.solicitud_necesidad">
                                    {{ item.solicitud_necesidad }}
                                    </v-list-item-subtitle>
                                    {{ getFundType(item.tipo_fondo) }}
                                </v-list-item-content>
                            </v-list-item>
                        </template>
                        <template v-slot:item="{ item, on, attrs }">
                            <v-list-item v-bind="attrs" v-on="on" :disabled="!(item.monto > 0)" style="width: 100%;">
                                <v-list-item-content>
                                    <v-list-item-title class="text-truncate" :title="item.cifrado_presupuestario">
                                        {{ item.cifrado_presupuestario }}
                                    </v-list-item-title>
                                    <v-list-item-subtitle class="text-truncate" :title="item.solicitud_necesidad">
                                        {{ item.solicitud_necesidad }}
                                    </v-list-item-subtitle>
                                    {{ getFundType(item.tipo_fondo) }}
                                </v-list-item-content>
                            </v-list-item>
                        </template>
                        </v-select>
                    </RetryDataLoading>
                    <v-textarea 
                        v-model="form.comentario" 
                        label="Comentario" 
                        placeholder="Comentario"
                        outlined 
                        light
                        class="mt-4"
                    />
                    <h3 class="pb-0 pl-4 text-uppercase" :class="!justForm && 'pt-4'">Fondos a utilizar</h3>
                    <v-divider color="gray" class="mt-2" /> 
                    <RetryDataLoading :loadable="asignaciones" :retryHandler="retryFundsLoading">
                        <v-col cols="12" class="mb-4">
                            <v-data-table
                                :headers="fondosHeaders"
                                :items="asignacionesFiltradas"
                                class="mt-3"
                                hide-default-footer
                                disable-pagination
                            >
                                <template v-slot:item.nombre_obs="{ item }"> 
                                    {{   `${item.Obs.codigo}- ${item.Obs.nombre}` }}
                                </template>
                                <template v-slot:item.monto_utilizar="{ item }"> 
                                    <v-text-field
                                        placeholder="Ingrese cantidad a utilizar"
                                        v-model="fondosAsignacion[item.id]"
                                        :error-messages="obtenerErrores[item.id]"
                                        outlined
                                        type="number"
                                        class="mt-2 mb-2"
                                        @keydown="onKeyDown($event, item.id)"
                                    />
                                </template>
                                <template v-slot:item.cifrado_presupuestario="{ item }"> 
                                    {{ item.cifrado_presupuestario }}
                                    <br>
                                    <span class="font-weight-bold"> {{ item.solicitud_necesidad }}</span>
                                </template>
                            </v-data-table>
                        </v-col>
                    </RetryDataLoading>
                    <div class="mt-4 d-flex flex-column-reverse flex-sm-row justify-center align-center" style="gap: 16px;">
                        <v-btn style="width: 100%;" @click.stop="internalOpen = false" class="flex-grow-1 flex-shrink-1" color="secondary">
                            Cerrar
                        </v-btn>
                        <v-btn 
                            :disabled="form.id_asignacion_presupuestaria && activeFundKind === 'project' || !isSubtotalValid"
                            :loading="fundAmounts.isLoading"
                            style="width: 100%;" 
                            type="submit" 
                            class="flex-grow-1 flex-shrink-1"
                            color="primary"
                        >
                            {{ buttonText }}
                        </v-btn>
                    </div>
                </v-form>
            </div>

        </div>
    </v-dialog>
</template>
<script>
import { required, minValue, maxValue, decimal, integer } from 'vuelidate/lib/validators'
import DataTableComponent from "@/components/DataTableComponent.vue";
import { transformHoursToString } from '@/utils/datetime';
import { RetryDataLoading } from '@/components/utils';
import { Validator } from '@/utils/form-validation.js';
import { CMConstants } from '@/utils/cm-constants.js';
import { validationMixin } from 'vuelidate';
import { createLoadable, setLoadableResponse, toggleLoadable } from '@/utils/loadable';
import { mapState } from 'vuex';
import moment from 'moment';
import { convertToFormData, removePropsFromObjectIfNil } from '@/utils/data';
import { isNil, debounce} from 'lodash';

export default {
    name: "UpsertCartItemComponent",
    mixins: [validationMixin],
    components: { RetryDataLoading, DataTableComponent},
    validations() {
        const numberValidator = this.offerMeta?.cantidad_decimal ? { decimal } : {  integer };
        const existenciasValidator = this.offerMeta?.manejo_existencias ? {  maxValue: maxValue(this.max) } : null;
        const numberValidatorAltura = this.offerMeta?.manejo_altura_decimal ? { decimal } : {  integer };
        const numberValidatorAnchura = this.offerMeta?.manejo_base_decimal ? { decimal } : {  integer };

        let cantidadRules = {}
        if (!this.manejoBase && !this.manejoAltura && this.manejoCantidad) {
            cantidadRules = {
                required,
                ...numberValidator,
                ...existenciasValidator,
                minValue: minValue(1),
            }
        };

        let montoRules = {}
        if (this.manejoMonto) {
            montoRules = {
                required,
                minValue: minValue(1),
            }
        };
        
        let alturaRules = {};

        if (this.manejoAltura) {
            const limiteAltura = this.offerMeta?.limite_altura?.split('-').map(Number);
            const limiteInferior = limiteAltura ? limiteAltura[0] : 1;
            const limiteSuperior = limiteAltura ? limiteAltura[1] : this.max;

            alturaRules = {
                required,
                ...numberValidatorAltura,
                ...existenciasValidator,
                minValue: minValue(limiteInferior),
                maxValue: maxValue(limiteSuperior),
            }
        }

        let anchuraRules = {}

        if (this.manejoBase) {
            const limiteAltura = this.offerMeta?.limite_base?.split('-').map(Number);
            const limiteInferior = limiteAltura ? limiteAltura[0] : 1;
            const limiteSuperior = limiteAltura ? limiteAltura[1] : this.max;
            anchuraRules = {
                required,
                ...numberValidatorAnchura,
                ...existenciasValidator,
                minValue: minValue(limiteInferior),
                maxValue: maxValue(limiteSuperior),
            };
        }

        let direccionRules = {}

        if (this.manejoDireccion) {
            direccionRules = {
                required,
                ...!this.isProductOffer,
            };
        };

        let fechaRules = {};
        let horaRules = {};
        let lineasRules = {};
        let vigenciaRules = {};
        let cifraRules = {};
        let gastoRules = {};


        if (this.manejoFecha ) {
            fechaRules = {
                required,
            };
        };
        if (this.manejoHora ) {
            horaRules = {
                required,
            };
        };
        if (this.manejoLineas ) {
            lineasRules = { required };
        };
        if (this.manejoVigencia ) {
            vigenciaRules = { 
                required, 
                minValue: minValue(1),
                maxValue: maxValue(12) 

            };
        };

        const esCMCombustible = this.manejoVigencia ||  this.manejoCifra && (this.manejoCantidad || this.manejoMonto)
        const esCMPublicaciones = this.manejoCantidad && this.manejoAltura || this.manejoBase ||  this.manejoLineas;
        if(esCMCombustible)
        {
            gastoRules = {
                required, 
            }
        }

        if (this.manejoCifra ) {
            cifraRules = { required };
        };


        const subtotalPublicaciones = (this.offerMeta.precio ?? 1) * (this.form.base ?? 1) * (this.form.altura ?? 1) * (this.form.cantidad ?? 1) * (this.form.lineas ?? 1);
        let subtotal = esCMCombustible && this.form.cifra && this.offerMeta.precio ? (this.offerMeta.precio * this.form.monto).toFixed(2) : esCMPublicaciones 
            ? subtotalPublicaciones 
            : this.subtotal;

        subtotal = this.configuracionDecimal ? Number(subtotal).toFixed(5) : Number(subtotal).toFixed(2);

        const validacionesMontos = {};
        const asignaciones = this.asignaciones.data;

        if (asignaciones.length > 0) {
            asignaciones.forEach((asignacion) => {
                validacionesMontos[asignacion.id] = { minValue: minValue(0), maxValue: maxValue(subtotal) };
            });

        }

        return {
            fondosAsignacion: {
                ...validacionesMontos,
            },
            form: {
                altura: alturaRules,
                base: anchuraRules,
                cantidad: cantidadRules,
                direccion: direccionRules,
                fecha_agendamiento: fechaRules,
                hora_agendamiento: horaRules,
                // id_fondo_solicitud_necesidad: { required },
                // id_asignacion_presupuestaria: { required },
                id_asignacion_gasto: this.precio_gasto > 0 ? gastoRules : {},
                monto: montoRules,
                lineas: lineasRules,
                vigencia: vigenciaRules,
                cifra: cifraRules,
            },
        };
    },
    props: {
        offer: { type: Object, required: false },
        isOpen: { type: Boolean, required: false },
        isEditing: { type: Boolean, required: true },
        justForm: { type: Boolean, default: false },
        offerMeta: { type: Object, required: true },
        fundsLoadable: { type: Object, required: true },
        obs: { type: String },
        retryFundsLoading: { type: Function },
        arbolObs: { type: Array },
    },
    emits: ['open-change', 'save'],
    data: () => ({
        branchOffices: '',
        modalFondo: false,
        asignacionPresupuestaria: null,
        // Validations
        minDate: new Date(),
        debounceTimeout: null,
        cumpleMonto: false, 
        id_obs: null,
        id_oferta_gasto: null,
        precio_gasto: null,
        validarGastosDebounced: null,
        fondosAsignacion:{},
        form: {
            altura:null,
            base:null,
            direccion: null,
            cantidad: null,
            // id_fondo_solicitud_necesidad: null,
            id_asignacion_presupuestaria: null,
            comentario:null,
            adjunto: null,
            fecha_agendamiento: null,
            hora_agendamiento: null,
            monto: null,
            lineas: null,
            vigencia: null,
            cifra: null,
            id_asignacion_gasto: null,
        },
        fundAmounts: createLoadable(null),
        asignaciones: createLoadable([]),
        fondosAgregados: createLoadable([]),
        listaOfertasGastos: createLoadable([]),
        regionsHeaders: [
            { align: 'center', sortable: false, text: 'Nombre', value: 'CMRegion.nombre' },
            { align: 'center', sortable: false, text: 'Tiempo de respuesta', value: 'tiempo_respuesta' },
            { align: 'center', sortable: false, text: 'Tiempo de respuesta emergencia', value: 'tiempo_respuesta_eme' },
            ],
        asignacionHeaders: [
            { align: 'center', sortable: false, text: 'Utilizar', value: 'asignacion_seleccionada' },
            { align: 'center', sortable: false, text: 'Unidad solicitante', value: 'unidad_solicitante' },
            { align: 'center', sortable: false, text: 'Cifrado presupuestario', value: 'cifrado_presupuestario' },
            { align: 'center', sortable: false, text: 'Obs', value: 'nombre_obs' },
            { align: 'center', sortable: false, text: 'Monto disponible', value: 'monto_disponible' },
        ],
        fondosHeaders: [
            { align: 'center', sortable: false, text: 'Cifrado presupuestario', value: 'cifrado_presupuestario' },
            { align: 'center', sortable: false, text: 'Monto a utilizar', value: 'monto_utilizar' },
        ],
        amountHeaders: [
            { align: 'center', sortable: false, text: 'Cifrado presupuestario', value: 'cifrado_presupuestario' },
            { align: 'center', sortable: false, text: 'Monto a utilizar', value: 'monto_utilizar' },
            { align: 'center', sortable: false, text: 'Acciones', value: 'acciones' },
        ],
    }),
    computed: {
        ...mapState("catalogoEnLinea", ['agendamientos', 'configuraciones']),
        internalOpen: {
            get() { return this.isOpen; },
            set(value){ 
                this.$emit('open-change', value);
            },
        },
        max() {
            return Number(this.offerMeta.disponibilidad) + Number(this.offerMeta.reservado);
        },
        modalTitle() {
            if (!this.isProductOffer) return 'Reservar servicio';
            return `${ this.isEditing ? 'Modificar' : 'Agregar' } reserva`;
        },
        buttonText() {
            if (!this.isProductOffer) return 'Reservar';

            return this.isEditing ? 'Modificar' : 'Agregar';
        },
        isProductOffer() {
            return this.offerMeta?.tipo_oferta === CMConstants.TIPO_PRODUCTO;
        },
        manejoCantidad() {
            return this.offerMeta.manejo_cantidad || this.offerMeta.cantidad_decimal;
        },
        manejoAltura() {
            return this.offerMeta.manejo_altura || this.offerMeta.manejo_altura_decimal ;
        },
        manejoBase() {
            return this.offerMeta.manejo_base || this.offerMeta.manejo_base_decimal;
        },
        manejoDireccion(){
            return this.offerMeta?.manejo_direccion;
        },
        manejoFecha(){
            return this.offerMeta?.manejo_fecha;
        },
        manejoHora(){
            return this.offerMeta?.manejo_hora;
        },
        manejoPdf(){
            return this.offerMeta?.manejo_pdf;
        },
        manejoMonto() {
            return this.offerMeta?.manejo_monto;
        },
        manejoLineas() {
            return this.offerMeta?.manejo_lineas;
        },
        manejoVigencia() {
            return this.offerMeta?.manejo_vigencia;
        },
        manejoCifra() {
            return this.offerMeta?.manejo_cifra;
        },
        direcciones() {
            return this.offerMeta?.direcciones;
        },
        offertId() {
            return this.offerMeta?.id_oferta;
        },
        activeFundKind() {
            if (!this.form.id_asignacion_presupuestaria) return 'empty';
            const fund = this.fundsLoadable.data.find((f) => (f.id === this.form.id_asignacion_presupuestaria));
            if (!fund) return 'empty';

            return fund.fondo_unidad.fondo.tipo_fondo === 2 ? 'voted' : 'project';
        },
        fundId() {
            return this.form.id_asignacion_presupuestaria;
        },
        // Form errors
        cantidadErrors() {
            const result = new Validator(this.$v.form.cantidad).detect().getResult();
            if (result.length === 0 && !this.isSubtotalValid) {
                result.push(`
                    El subtotal ($${this.subtotal.toFixed(2)}) excede el monto disponible asignado ($${ this.availableAmount.toFixed(2) }).
                `);
            }

            return result;
        },
        alturaErrors() {
            return new Validator(this.$v.form.altura).detect().getResult();
        },
        anchuraErrors() {
            return new Validator(this.$v.form.base).detect().getResult();
        },
        direccionErrors() {
            return new Validator(this.$v.form.direccion).detect().getResult();
        },
        // id_fondo_solicitud_necesidadErrors() {
        //     return new Validator(this.$v.form.id_fondo_solicitud_necesidad).detect().getResult();
        // },
        id_asignacion_presupuestariaErrors() {
            return new Validator(this.$v.form.id_asignacion_presupuestaria).detect().getResult();
        },
        availableAmount() {
            const totalAsignaciones = this.asignaciones.data.reduce((acc, a) => acc + +a.monto, 0)
            return this.fundAmounts.data ? Number(totalAsignaciones) : 0;
        },
        subtotal() {
            return this.form.cantidad && this.offerMeta.precio ? this.form.cantidad * this.offerMeta.precio : 0;
        },
        montoErrors() {
            return new Validator(this.$v.form.monto).detect().getResult();
        },
        lineasErrors() {
            return new Validator(this.$v.form.lineas).detect().getResult();
        },
        isSubtotalValid() {
            if (!this.form.cantidad || !this.fundAmounts.data) return true;
            return this.subtotal.toFixed(2) <= this.availableAmount;
        },
        fecha_agendamientoErrors() {
            return new Validator(this.$v.form.fecha_agendamiento).detect().getResult();
        },
        hora_agendamientoErrors() {
            return new Validator(this.$v.form.hora_agendamiento).detect().getResult();
        },
        fecha_agendamientoValue() {
            if (!this.form.fecha_agendamiento) return;
            return moment(this.form.fecha_agendamiento).format("DD-MM-YYYY")
        },
        hora_agendamientoValue() {
            if (!this.form.hora_agendamiento) return;
            
            return moment(this.form.hora_agendamiento).format("HH:mm");
        },
        fecha_agendamientoErrors() {
            return new Validator(this.$v.form.fecha_agendamiento).detect().getResult();
        },
        hora_agendamientoErrors() {
            return new Validator(this.$v.form.hora_agendamiento).detect().getResult();
        },
        fecha_agendamientoValue() {
            if (!this.form.fecha_agendamiento) return;
            return moment(this.form.fecha_agendamiento).format("DD-MM-YYYY")
        },
        hora_agendamientoValue() {
            if (!this.form.hora_agendamiento) return;
            
            return moment(this.form.hora_agendamiento).format("HH:mm");
        },
        listaAsignaciones() {
            const obsPadre = this.arbolObs?.find((obs) => isNil(obs.id_obs_padre));

            return obsPadre
            ? this.asignaciones.data.filter((asignacion) => asignacion.Obs.id === obsPadre.id)
            : this.asignaciones.data;
        },
        vigenciaErrors() {
            return new Validator(this.$v.form.vigencia).detect().getResult();
        },
        cifraErrors(){
            return new Validator(this.$v.form.cifra).detect().getResult();
        },
        ValorCifra() {
            const esCMCombustible = (this.offerMeta.manejo_cantidad || this.offerMeta.manejo_monto) && this.offerMeta.manejo_cifra && this.offerMeta.manejo_vigencia;
            return esCMCombustible ? "Cantidad tarjetas" : "Cifra";
        },
        ValorCantidad() {
            const esCMCombustible = (this.offerMeta.manejo_cantidad || this.offerMeta.manejo_monto) && this.offerMeta.manejo_cifra && this.offerMeta.manejo_vigencia;
            return esCMCombustible ? "Cantidad galones" : "Cantidad";
        },
        ofertasGastos() {
            return this.asignaciones.data.filter((a) => a.Obs.id === this.id_obs)
        },
        segundoFondo(){
            return !!(this.form.cantidad || this.form.monto) && this.form.cifra && this.form.vigencia
        },
        idOfertaGastoErrors(){
            return new Validator(this.$v.form.id_asignacion_gasto).detect().getResult();
        }, 
        amountsData() {
            const amounts = this.fundAmounts.data;

            return amounts 
            ? [{ 
                ...asignacionEncontrada,
                monto_total: `$${Number(amounts.monto_total).toFixed(2)}`, 
                monto_incluyendo_reservas: `$${Number(amounts.monto_incluyendo_reservas).toFixed(2)}` 
            }] 
            : [];
        },
        mostrarFondoGastoAdministrativo(){
            return (!this.segundoFondo && this.precio_gasto > 0) || (this.segundoFondo && this.precio_gasto > 0);
        },
        configuracionDecimal(){
            return this.configuraciones.data.find(c => c.operacion === 'precios_mas_decimales');
        },
        obtenerErrores() {
            const errors = {};
            this.asignaciones.data.forEach((asignacion) => {
                const validador = this.$v.fondosAsignacion[asignacion.id];
                validador.$touch();

                if (!validador) return [];

                let montoUtilizado = 0;

                for (let fondos in this.fondosAsignacion) {
                    montoUtilizado += Number(this.fondosAsignacion[fondos] ?? 0);
                }
                 montoUtilizado = this.configuracionDecimal ? Number(+montoUtilizado).toFixed(this.configuracionDecimal.valor) : Number(+montoUtilizado).toFixed(2);

                const esCMCombustible = !!(this.form.cantidad || this.form.cifra) && !!this.form.vigencia && !!this.form.monto;
                const esCMPublicaciones = !!this.form.cantidad && !!this.form.altura || !!this.form.base || !!this.form.lineas;
                const subtotalPublicaciones = (this.offerMeta.precio || 1) * (this.form.base || 1) * (this.form.altura || 1) * (this.form.cantidad || 1) * (this.form.lineas || 1);

                let subtotal = esCMCombustible && this.offerMeta.precio  
                    ? this.offerMeta.precio * this.form.monto 
                    : esCMPublicaciones 
                    ? subtotalPublicaciones 
                    : Number(this.subtotal);

                subtotal = !!this.configuracionDecimal ? subtotal.toFixed(this.configuracionDecimal.valor) : subtotal.toFixed(2);
                const errores = new Validator(this.$v.fondosAsignacion[asignacion.id]).detect().getResult();

                if (subtotal <= 0) {
                    errores.push(`No hay un subtotal`);
                }

                const diferencia = this.configuracionDecimal ? Number(+montoUtilizado - +subtotal).toFixed(this.configuracionDecimal.valor) : Number(+montoUtilizado - +subtotal).toFixed(2);
                if (+montoUtilizado > subtotal) {
                    errores.push(`El valor excede por ${diferencia}`);
                }
                if (+montoUtilizado < subtotal) {
                    errores.push(`Monto pendiente ${Math.abs(diferencia)}`);
                }

                errors[asignacion.id] = errores;
            });

            return errors;
        },
        asignacionesFiltradas() {
            const obsPadre = this.arbolObs?.find((obs) => isNil(obs.id_obs_padre));
            return this.asignaciones.data.filter(asignacion => asignacion.seleccionado === asignacion.id && asignacion.Obs.id === obsPadre.id);
        }
    },
    methods: {
        async submit() {
            this.$v.$touch();
            if (this.$v.$invalid) return;   
            const asignacion = this.asignaciones.data.filter(item => item.id && item.seleccionado).map(item => ({
                id: item.id,
                monto_utilizar: this.fondosAsignacion[item.id]
            })).filter(item => item.monto_utilizar > 0);

            if (asignacion.length === 0) {
                return this.pushAppMessage({
                    message: "Por favor, selecciona al menos una asignación a utilizar.",
                    show: true,
                    type: "error",
                });
            }

            const dataEnviar = {
                ...this.form,
                id: this.offerMeta.idProductoSolicitud,
                id_oferta: this.offerMeta.id_oferta,
                id_producto: this.offerMeta.id_producto,
                asignacion,
                id_oferta_gastos: this.id_oferta_gasto,
                id_asignacion_gasto: this.form.id_asignacion_gasto
            };


            if (dataEnviar.direccion) {
                dataEnviar.direccion = this.direcciones.find((d) => d.id === dataEnviar.direccion).direccion;
            }
            
            const formData = convertToFormData(removePropsFromObjectIfNil(dataEnviar, Object.keys(dataEnviar)));
            
            this.clearForm();
            this.$emit('save', formData);
        },
        clearForm() {
            this.form = {};
            this.$v.form.$reset();
            this.$refs.form?.reset();
        },
        formatHour(hour) {
            return transformHoursToString(hour);
        },
        getFundType(tipoFondo) {
            return tipoFondo === 2 ? 'Votado' : 'Proyecto';
        },
        // Data
        async loadFundAmounts(fundId) {
            toggleLoadable(this.fundAmounts);
            const productId = this.offerMeta?.idProductoSolicitud;
            const { data } = await this.services.Product.getFundAmounts(fundId, productId)
            setLoadableResponse(this.fundAmounts, data);
        },
        async obtenerFondosAgregados(){
            toggleLoadable(this.fondosAgregados)
            const { data } = await this.services.Product.fondosAgregados();
            setLoadableResponse(this.fondosAgregados, data);

            this.arregloFondos = this.fondosAgregados.data.map(fondo => ({
                cifrado_presupuestario: fondo.cifrado_presupuestario,
                monto: fondo.monto,
            }));

            this.totalFondo = this.arregloFondos.reduce((acc, af) => acc + +af.monto, 0);
        },
        retryAmountLoading() {
            this.loadFundAmounts(this.form.id_asignacion_presupuestaria)
        },
        async listarAsignacion(){
            toggleLoadable(this.asignaciones);
            const { data } = await this.services.Product.obtenerAsignacionPresupuestaria();
            setLoadableResponse(this.asignaciones, data);
        },
        async validacionGastosAdministrativo(){
            toggleLoadable(this.listaOfertasGastos);
            const { data } = await this.services.PurchaseRequest.obtenerListaOfertasGastos(this.offerMeta.id_oferta);
            setLoadableResponse(this.listaOfertasGastos, data);
        },
        async validarGastos(){
            const {
                    vigencia,
                    cifra,
                    cantidad,
                    monto,
                } = this.form

            if(!(vigencia && cifra && (cantidad || monto))) return;

            try {
                const { data: { id_obs, id_oferta_gasto, precio } } = await this.services.PurchaseRequest.validarCantidadGasolina(this.offertId, {
                    cantidad_tarjetas: cifra,
                    vigencia,
                    cantidad,
                    monto,
                })
                this.id_obs = id_obs
                this.id_oferta_gasto = id_oferta_gasto
                this.precio_gasto = +precio
            } catch (error) {
                this.form.id_asignacion_gasto = null;
                this.id_obs = null;
                this.id_oferta_gasto = null;
                this.precio_gasto = null;
                throw error
            }

            return { valido: true }
        },  
        mostrarAsignacionesObs(item){
            const obsPadre = this.arbolObs?.find((obs) => isNil(obs.id_obs_padre));
            return obsPadre.id === item.Obs.id
        },
        onCheckboxChange(item) {
            if (!item.seleccionado) {
                // Si el checkbox ha sido deseleccionado, se limpia su valor.
                if (this.fondosAsignacion && this.fondosAsignacion[item.id]) {
                    this.fondosAsignacion[item.id] = null;
                }
            }
        },
        onKeyDown(event, id) {
            const fieldValue = this.fondosAsignacion[id] || ''; 
            const newValue = fieldValue + event.key;

            const regex = this.configuracionDecimal
                ? /^\d+(\.\d{0,5})?$/
                : /^\d+(\.\d{0,2})?$/;

            const specialKeys = ['Backspace', 'Delete', 'ArrowLeft', 'ArrowRight', 'Tab'];
            if (event.ctrlKey && event.key === 'a') return;

            if (!regex.test(newValue) && !specialKeys.includes(event.key)) {
                event.preventDefault();
            }
        },
    },
    watch: {
        'form.altura': {
            handler(newValue, oldValue) {
                if (newValue !== null && newValue !== undefined && !String(newValue).match(/^(\d+(\.5|\.?)?)?$/)) {
                    this.$nextTick(() => {
                        this.form.altura = oldValue;
                    });
                }
            },
        },
        'form.base': {
            handler(newValue, oldValue) {
                if (newValue !== null && newValue !== undefined && !String(newValue).match(/^(\d+(\.5|\.?)?)?$/)) {
                    this.$nextTick(() => {
                        this.form.base = oldValue;
                    });
                }
            },
        },
        'form.cantidad': {
            handler(newValue, oldValue) {
                if (newValue !== null && newValue !== undefined && !String(newValue).match(/^(\d+(\.5|\.?)?)?$/)) {
                    this.$nextTick(() => {
                        this.form.cantidad = oldValue;
                    });
                }
            },
        },
        'form.monto'() {
            this.validarGastosDebounced();
        },
        'form.vigencia'() {
            this.validarGastosDebounced();
        },
        'form.cifra'() {
            this.validarGastosDebounced();
        },
        isOpen(value) {
            const agendamiento = this.offerMeta.hora_agendamiento && this.offerMeta.fecha_agendamiento 
                ? moment(`${this.offerMeta.fecha_agendamiento} ${this.offerMeta.hora_agendamiento}`, 'YYYY-MM-DD HH:mm')
                : null;

            if (!value || !this.offertId) return;
            // this.form.id_fondo_solicitud_necesidad = this.offerMeta.id_fondo_solicitud_necesidad;            
            this.form.id_asignacion_presupuestaria = this.offerMeta.id_asignacion_presupuestaria;
            this.form.altura = this.offerMeta?.altura;
            this.form.base = this.offerMeta?.base;
            this.form.fecha_agendamiento = agendamiento?.toDate();
            this.form.hora_agendamiento = agendamiento?.toDate();
            this.form.comentario = this.offerMeta?.comentario;
            this.form.monto = this.offerMeta?.monto;
            this.form.vigencia = this.offerMeta?.vigencia;
            this.form.cifra = this.offerMeta?.cifra;
            if (this.offerMeta?.reservado > 0) this.form.cantidad = this.offerMeta.reservado;
            if (Array.isArray(this.offerMeta?.direcciones)) {
                const direccion = this.offerMeta.direcciones.find((d) => d.direccion === this.offerMeta.direccion);
                if (direccion) this.form.direccion = direccion.id;
            }
            this.listarAsignacion();
            if((this.form.monto) && this.form.cifra && this.form.vigencia ){                
                this.validacionGastosAdministrativo();
            }
        },
        fundId(value) {
            this.fundAmounts = createLoadable(null);
            if (!value) return;

            //Asignaciones encontradas
            const asignaciones = this.asignaciones.data; 
            const asignacionEncontrada = asignaciones.find((a) => a.id === this.fundId)
            this.asignacionPresupuestaria = asignacionEncontrada;
            
            this.loadFundAmounts(value);
        },
    },
    created() {
        this.validarGastosDebounced = debounce(() => {
            const camposRequeridos = [this.form.cifra, this.form.vigencia, this.form.monto];
            const camposIncompletos = camposRequeridos.some(isNil);

            if (camposIncompletos) return;

            this.validarGastos();
        }, 1000);
    },
}
</script>
<style scoped>
.v-list-item.v-list-item--disabled .v-list-item__content {
    cursor: no-drop !important;
    color: rgba(0,0,0,.38) !important;
}
</style>
