import { http_client_with_status } from "@/plugins/http_client";

const cargarNumerosMipymes = (filtros) => http_client_with_status("/api/v1/reporteria-mipymes/numero-mipymes", filtros, "get");

const cargarEstadisticasAdjudicacion = (filtros) => http_client_with_status("/api/v1/reporteria-mipymes/adjudicaciones-por-modalidad", filtros, "get");

const cargarEstadisticasContratacion = (filtros) => http_client_with_status("/api/v1/reporteria-mipymes/contrataciones-por-modalidad", filtros, "get");

const cargarNumeroInstitucionesInformacionReportada = (filtros) => http_client_with_status("/api/v1/reporteria-mipymes/instituciones-informacion-reportada", filtros, "get");

const cargarEstadisticasContratacionesPorTipoInstitucion = (filtros) => http_client_with_status("/api/v1/reporteria-mipymes/contrataciones-por-tipo-institucion", filtros, "get");

const cargarComparacionMontosContratados = (filtros) => http_client_with_status("/api/v1/reporteria-mipymes/comparacion-montos-contratados", filtros, "get");

const cargarClasificacionesEmpresariales = (filtros) => http_client_with_status("/api/v1/reporteria-mipymes/clasificaciones-empresariales", filtros, "get");

const cargarCatalogoTipoInstituciones = (filtros) => http_client_with_status("/api/v1/reporteria-mipymes/catalogo-tipo-instituciones", filtros, "get");

export default {
    cargarNumerosMipymes,
    cargarEstadisticasAdjudicacion,
    cargarEstadisticasContratacion,
    cargarNumeroInstitucionesInformacionReportada,
    cargarEstadisticasContratacionesPorTipoInstitucion,
    cargarComparacionMontosContratados,
    cargarClasificacionesEmpresariales,
    cargarCatalogoTipoInstituciones,
};
