import PaacProcesosServices from "@/services/Paac.services";
import moment from "moment";

export default {
  namespaced: true,
  state: {
    busqueda: null,
    modalAuditoria: false,
    mensaje: null,
    fechaSeleccionada: null,
    horaSeleccionada: null,
    isTimePickerDisabled: true,
    horaMinima: null,
    minFecha: new Date().toLocaleDateString('en-CA'),
    modalProgramar: false,
    porProveedor: false,
    fechaInicio: null,
    fechaFin: null,
  },
  getters: {
    fechaHoraFormateada(state) {
      if (state.fechaSeleccionada && state.horaSeleccionada) {
        return `${state.fechaSeleccionada} ${state.horaSeleccionada}`;
      }
      return "";
    },
  },
  mutations: {
    setBusqueda(state, id) {
      state.busqueda = id;
    },
    setModalAuditoria(state, value) {
      state.modalAuditoria = value;
    },
    setMensaje(state, mensaje) {
      state.mensaje = mensaje;
    },
    setFecha(state, fecha) {
      state.fechaSeleccionada = fecha;
    },
    setHora(state, hora) {
      state.horaSeleccionada = hora;
    },
    setTimePickerDisabled(state, value) {
      state.isTimePickerDisabled = value;
    },
    setHoraMinima(state, hora) {
      state.horaMinima = hora;
    },
    setModalProgramar(state, value) {
      state.modalProgramar = value;
    },
    setFechaInicio(state, fecha){
      state.fechaInicio = fecha;
    },
    setFechaFin(state, fecha){
      state.fechaFin = fecha;
    },
    setPorProveedor(state, value){
      state.porProveedor = value;
    },
    resetModalState(state) {
      // state.modalProgramar = false;
      state.fechaSeleccionada = null;
      state.horaSeleccionada = null;
      state.isTimePickerDisabled = true;
      state.horaMinima = null;
      // state.modalAuditoria = false;
    },
  },
  actions: {
    async generarInmediato({ state, commit }) {
      try {
        console.log("Generando reporte...", state.busqueda);

        const { status, data } = await PaacProcesosServices.generarAuditoriaSubasta(
          state.busqueda,
          {
            tipo: state.porProveedor,
            fecha_inicio: moment(state.fechaInicio).startOf('minute').format("YYYY-MM-DD HH:mm:ss"),
            fecha_fin: moment(state.fechaFin).startOf('minute').format("YYYY-MM-DD HH:mm:ss"),
          }
        );

        if (status === 200) {
          commit("setMensaje", {
            show: true,
            type: "success",
            color: "info",
            message: data?.message ?? "Se está generando la auditoría.",
          });
        }
      } catch (error) {
        console.error("Error generando auditoría:", error);
        commit("setMensaje", {
          show: true,
          type: "error",
          color: "red",
          message: "Error al generar la auditoría.",
        });
      } 
      // finally {
      //   commit("setModalAuditoria", false);
      // }
    },
    onDateChange({ commit, state }) {
      commit("setHora", null);
      commit("setTimePickerDisabled", !state.fechaSeleccionada);
      commit("setHoraMinima", obtenerHoraMinima(state));
    },

    async confirmarProgramacion({ state, getters, commit }) {
      if (state.fechaSeleccionada && state.horaSeleccionada) {
        const { status } = await PaacProcesosServices.generarAuditoriaSubasta(
          state.busqueda, 
          {
            fecha_seleccionada: getters.fechaHoraFormateada,
            tipo: state.porProveedor,
            fecha_inicio: moment(state.fechaInicio).startOf('minute').format("YYYY-MM-DD HH:mm:ss"),
            fecha_fin: moment(state.fechaFin).startOf('minute').format("YYYY-MM-DD HH:mm:ss"),
          }
        );
    
        if (status === 200) {
          commit("setMensaje", {
            show: true,
            type: "success",
            color: "info",
            message: `Reporte programado para: ${getters.fechaHoraFormateada}`,
          });
        }
    
        console.log("Reporte programado para:", getters.fechaHoraFormateada);
    
        commit("resetModalState");
      } else {
        commit("setMensaje", {
          show: true,
          type: "error",
          color: "red",
          message: "Por favor selecciona una fecha y hora válidas.",
        });
      }
    }    
  },
};

function obtenerHoraMinima(state) {
  const now = new Date();
  if (state.fechaSeleccionada === state.minFecha) {
    const hours = now.getHours();
    const minutes = now.getMinutes();
    return `${hours}:${minutes < 10 ? "0" + minutes : minutes}`;
  }
  return null;
}
