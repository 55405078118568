import { http_client } from "@/plugins/http_client";
import { http_client_with_status } from "@/plugins/http_client";

// petición GET
const guardarRegistro = async (body) =>
  http_client("api/v1/compra/exterior", body, "POST");
const editarRegistro = async (body, idCompra) =>
  http_client(`api/v1/compra/exterior/actualizar/${idCompra}`, body, "put");
const listarRegistro = async (filters) =>
  http_client("api/v1/compra/exterior", filters);
const listarInstituciones = async (filters) =>
  http_client("api/v1/compra/exterior/instituciones", filters);
const listDivisas = async (filters = {}) =>
  http_client("api/v1/compra/exterior/divisas", filters);

const revisionCompras = (filters) =>
  http_client_with_status("/api/v1/compra/exterior/informe", filters, 'get');

const usuariosInstitucion = (filters) =>
  http_client_with_status("/api/v1/compra/exterior/informe/usuarios-institucion", filters, 'get');

const visualizarDocumento = (idDocumento) =>
  http_client_with_status(`/api/v1/compra/exterior/informe/documentos/${idDocumento}` , null, "get", {}, { responseType: 'blob' });

const descargarInformeComprasExterior = (filtros) =>
  http_client_with_status(`/api/v1/compra/exterior/generar/pdf` , filtros, "get", {}, { responseType: 'blob' });

const descargarExcelCompras = (filtros) =>
  http_client_with_status(`/api/v1/compra/exterior/generar/excel` , filtros, "get", {}, { responseType: 'blob' });

const visualizarDocumentoEliminacion = (ruta) =>
  http_client_with_status(`/api/v1/compra/exterior/informe/adjunto_eliminacion` , ruta, "get", {}, { responseType: 'blob' });

const uploadFile = async (body) =>
  await http_client_with_status(`/api/v1/compra/exterior/informe/archivo`, body, "post");

const eliminarCompra = async (body) =>
  await http_client_with_status(`/api/v1/compra/exterior/informe/eliminar`, body, "put");

const unidadesInstitucion = async (filters) =>
  http_client("api/v1/unidades", filters);

const getFuentesFinanciamiento = async (filters) =>
  http_client("api/v1/compra/exterior/fuentes-financiamiento", filters);

const saveFuenteFinanciamiento = async (body) =>
  http_client("api/v1/compra/exterior/fuentes-financiamiento", body, "POST");

const updateFuenteFinanciamiento = async (idFuente, body) =>
  http_client(`api/v1/compra/exterior/fuentes-financiamiento/${idFuente}`, body, "put");

const desactivarFuenteFinanciamiento = async (idFuente) =>
  http_client(`api/v1/compra/exterior/fuentes-financiamiento/desactivar/${idFuente}`, null, "delete");

const activarFuenteFinanciamiento = async (idFuente) =>
  http_client(`api/v1/compra/exterior/fuentes-financiamiento/activar/${idFuente}`, null, "patch");

export default {
  editarRegistro,
  guardarRegistro,
  listarRegistro,
  listarInstituciones,
  listDivisas,
  revisionCompras,
  visualizarDocumento,
  usuariosInstitucion,
  uploadFile,
  eliminarCompra,
  visualizarDocumentoEliminacion,
  unidadesInstitucion,
  descargarInformeComprasExterior,
  getFuentesFinanciamiento,
  saveFuenteFinanciamiento,
  updateFuenteFinanciamiento,
  desactivarFuenteFinanciamiento,
  activarFuenteFinanciamiento,
  descargarExcelCompras
};
